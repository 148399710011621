import axios from "axios";

export const createFAQ = (faq) => {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/faq`, faq);
};

export const updateFAQ = (faq) => {
  return axios.put(`${process.env.REACT_APP_SERVER_URL}/api/v1/faq`, faq);
};

export const updateFAQOrder = (faq) => {
  return axios.put(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/faq/updatefaqorder`,
    faq
  );
};

export const getFAQs = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/faq?page=${page}&size=${limit}`
  );
};
export const getFAQbySection = (section) => {
  return axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/faq/getfaqsbysection?section=${section}`
  );
};

export const deleteFAQ = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/faq?id=${id}`
  );
};
