import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { createFAQ, updateFAQ } from "./Helper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Editor } from "@tinymce/tinymce-react";

const FAQUpdate = () => {
  const context = useOutletContext();
  const location = useLocation();
  const id = location.state?.id == null ? null : location?.state?.id;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      section: location.state?.section,
      question: location.state?.question,
      answer: location.state?.answer,
    },
  });

  const [editorContent, setEditorContent] = useState(
    location?.state?.answer ?? "<p>Please delete this content and add new content otherwise the blog will not be created.</p>"
  );

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };

  useEffect(() => {
    context(["FAQ", id == null ? "Add FAQ" : "Update FAQ"])
  }, [])

  const submit = (formData) => {
    console.log("Entere")
    if (id == null) {
      createFAQ({
        question: formData?.question,
        answer: editorContent,
        section: formData?.section,
      })
        .then((data) => {
          console.log(data.data);
          navigate("/FAQ");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateFAQ({
        id: id,
        question: formData?.question,
        answer: editorContent,
        section: formData?.section,
      })
        .then((data) => {
          console.log(data.data);
          navigate("/FAQ");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#644fdc" }}
        >
          {id == null ? "Create" : "Update"} FAQ
        </h3>
      </div>
      <br />
      <div class="overflow-x-auto relative ">
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <div className="pt-2">
            <FormControl className="w-80" size="small">
              <InputLabel size="small" id="demo-simple-select-label">
                Section *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={location.state?.section}
                label="Section *"
                size="small"
                {...register("section")}
              >
                <MenuItem value={"All Courses"}>All Courses</MenuItem>
                <MenuItem value={"Product Management"}>Product Management</MenuItem>
                <MenuItem value={"Data Science"}>Data Science</MenuItem>
                <MenuItem value={"Software Development"}>Software Development</MenuItem>
                <MenuItem value={"Data Analytics"}>Data Analytics</MenuItem>
                <MenuItem value={"Compare Courses"}>Compare Courses</MenuItem>
                <MenuItem value={"Blogs"}>Blogs</MenuItem>
              </Select>
            </FormControl>
            {errors.section && (
              <p className="text-red-500 text-xs">Section is required</p>
            )}
            <br />
            <TextField
              className="w-1/2"
              minRows={2}
              multiline
              id="outlined-basic"
              {...register("section")}
              label="Section *"
              margin="normal"
              variant="outlined"
              size="small"
            />
            <br />
            <TextField
              className="w-1/2"
              minRows={2}
              multiline
              id="outlined-basic"
              {...register("question", { required: true })}
              label="Question *"
              margin="normal"
              variant="outlined"
              size="small"
            />
            {errors.question && (
              <p className="text-red-500 text-xs">Question is required</p>
            )}
          </div>
          {/* <TextField
            id="outlined-basic"
            {...register("answer", { required: true })}
            multiline
            minRows={10}
            label="Answer *"
            margin="normal"
            variant="outlined"
            size="small"
            fullWidth
          /> */}
          <Editor
            apiKey="qw3c3bk7e87wky4em7jtqpijqm6nx8gt4mmb8ew5krn5pvvb"
            value={editorContent}
            init={{
              plugins: ["advlist autolink lists link"],
            }}
            onEditorChange={handleEditorChange}
          />
          {errors.name && (
            <p className="text-red-500 text-xs">Answer is required</p>
          )}
          <br />
          <div className="text-right pt-10">
            <Button
              type="submit"
              variant="contained"
              style={{ color: "white" }}
            >
              {id == null ? "Add" : "Update"} FAQ
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FAQUpdate;
