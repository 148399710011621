import Holder from "./Holder";
import TinyMCEEditor from "./components/TinymceEditor";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Blogs from "./pages/blogs/blogs";
import FAQ from "./pages/FAQ/FAQ";
import FAQList from "./pages/FAQ/FAQList";
import FAQUpdate from "./pages/FAQ/FAQUpdate";
import FAQSort from "./pages/FAQ/FAQSort";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Holder />}>
            <Route path="/blog" element={<Blogs />}>
              <Route path="createblog" element={<TinyMCEEditor />} />
            </Route>
            <Route path="/FAQ" element={<FAQ />}>
              <Route path="" element={<FAQList />} />
              <Route path="Create" element={<FAQUpdate />} />
              {/* <Route path="Sort" element={<FAQSort />} /> */}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
