import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import LogoutIcon from '@mui/icons-material/Logout';
// import { logout } from "../pages/Auth/Helper";
function Sidebar() {
    let location = useLocation();
    let navigate = useNavigate();
    let [selectedpanel, setselectedpanel] = useState("blog");
    let [drop, setdrop] = useState(0);
    let [toggle, setToggle] = useState(true);
    let [arrow, setarrow] = useState({
        blog: false,
        faq: false
    });
    const [active, setActive] = useState({
        blog: "hidden py-2 space-y-2",
        faq: "hidden py-2 space-y-2",
    });
    const handleChange = (e, name) => {
        e.preventDefault();
        setselectedpanel(name);
        setdrop(0);
        if (active[name] === "py-2 space-y-2") {
            setActive({
                ...active,
                [name]: "hidden py-2 space-y-2",
            });
            setarrow({ ...arrow, [name]: false });
        } else {
            setActive({
                blog: "hidden py-2 space-y-2",
                faq: "hidden py-2 space-y-2",
            });
            setActive({
                ...active,
                [name]: "py-2 space-y-2",
            });
            setarrow({ ...arrow, [name]: true });
            console.log(active);
            setToggle(!toggle);
        }
    };
    useEffect(() => {
        console.log("toggled");
    }, [active]);
    return (
        <div class="h-screen " aria-label="Sidebar">
            <div
                class="min-h-screen overflow-y-auto py-4 px-3  sticky left-0"
                style={{ backgroundColor: "#f9f7fa" }}
            >
                <ul class="space-y-4">
                    <li>
                        <div className="flex flex-row items-center">
                            <Link
                                href="#"
                                className="flex items-center w-full text-base font-normal text-gray-900 rounded-lg text-white"
                            >
                                <img src={"https://media.licdn.com/dms/image/D4D0BAQGsReThPrDapw/company-logo_200_200/0/1689857370203?e=1698278400&v=beta&t=Nkwqy8sSqce61gtb03CkITJwkPBQR7K2bg8QEb61D7g"} className="w-16" alt="tngrowth" />
                            </Link>
                            <div className="-ml-40">tnGrowth</div>
                        </div>
                    </li>
                </ul>
                <br />
                <ul>
                    <li>
                        <button
                            onClick={(e) => handleChange(e, "blog")}
                            type="button"
                            style={{
                                backgroundColor:
                                    selectedpanel === "blog" ? "#644fdc" : "",
                            }}
                            className={`flex items-center w-full p-4 text-base font-normal text-white  hover:bg-[#E6FCF7] ${selectedpanel === "blog"
                                ? "text-white"
                                : "text-gray-400"
                                }`}
                            aria-controls="dropdown-example"
                            data-collapse-toggle="dropdown-example"
                        >
                            <span
                                className="flex-1 ml-3 text-left whitespace-nowrap"
                                sidebar-toggle-item
                            >
                                Blog
                            </span>
                            {arrow.blog ? (
                                <svg
                                    sidebar-toggle-item
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    width="16"
                                    className="w-6 h-6"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10.1658 4.23431C10.4782 4.54673 10.4782 5.05327 10.1658 5.36569L7.53147 8L10.1658 10.6343C10.4782 10.9467 10.4782 11.4533 10.1658 11.7657C9.85336 12.0781 9.34683 12.0781 9.03441 11.7657L5.83441 8.56569C5.52199 8.25327 5.52199 7.74673 5.83441 7.43431L9.03441 4.23431C9.34683 3.9219 9.85336 3.9219 10.1658 4.23431Z"
                                        fill="#9E9EA6"
                                    />
                                </svg>
                            )}
                        </button>
                        <ul
                            id="dropdown-example"
                            className={active.blog}
                            style={{ backgroundColor: "#efe3ff" }}
                        >
                            <li
                                onClick={() => {
                                    setdrop(1);
                                    setselectedpanel("blog");
                                }}
                            >
                                <Link
                                    to="/blog/createblog"
                                    className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${selectedpanel === "blog" && drop === 1
                                        ? "text-black font-semibold"
                                        : "text-gray-400 font-normal"
                                        }`}
                                >
                                    Create Blog
                                </Link>
                            </li>
                            {/* <li
                                onClick={() => {
                                    setdrop(2);
                                    setselectedpanel("pendingApproval");
                                }}
                            >
                                <Link
                                    to="/PendingApproval/JobApproval"
                                    className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${selectedpanel === "pendingApproval" && drop === 2
                                        ? "text-black font-semibold"
                                        : "text-gray-400 font-normal"
                                        }`}
                                >
                                    Job Approval
                                </Link>
                            </li>
                            <li
                                onClick={() => {
                                    setdrop(3);
                                    setselectedpanel("pendingApproval");
                                }}
                            >
                                <Link
                                    to="/PendingApproval/ProjectApproval"
                                    className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${selectedpanel === "pendingApproval" && drop === 3
                                        ? "text-black font-semibold"
                                        : "text-gray-400 font-normal"
                                        }`}
                                >
                                    Project Approval
                                </Link>
                            </li>
                            <li
                                onClick={() => {
                                    setdrop(4);
                                    setselectedpanel("pendingApproval");
                                }}
                            >
                                <Link
                                    to="/PendingApproval/VendorApproval"
                                    className={`flex items-center p-2 pl-11 w-full text-base text-gray-900 rounded-lg transition duration-75 group  ${selectedpanel === "pendingApproval" && drop === 4
                                        ? "text-black font-semibold"
                                        : "text-gray-400 font-normal"
                                        }`}
                                >
                                    Vendor Approval
                                </Link>
                            </li> */}
                        </ul>
                    </li>
                    <li>
                        <button
                            onClick={(e) => {
                                handleChange(e, "faq");
                                navigate("/FAQ");
                            }}
                            type="button"
                            // style={{
                            //     backgroundColor: selectedpanel === "faq" ? "#644fdc" : "",
                            // }}
                            // className={`flex items-center w-full p-4 text-base font-normal text-white  hover:bg-[#E6FCF7] ${selectedpanel === "faq" ? "text-white" : "text-gray-400"
                            //     }`}
                            style={{
                                backgroundColor:
                                    selectedpanel === "blog" ? "#644fdc" : "",
                            }}
                            className={`flex items-center w-full p-4 text-base font-normal text-white  hover:bg-[#E6FCF7] ${selectedpanel === "faq"
                                ? "text-white"
                                : "text-gray-400"
                                }`}
                            aria-controls="dropdown-example"
                            data-collapse-toggle="dropdown-example"
                        >
                            <span
                                className="flex-1 ml-3 text-left whitespace-nowrap"
                                sidebar-toggle-item
                            >
                                FAQ Manager
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
