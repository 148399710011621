import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const TinyMCEEditor = () => {
  const [title, setTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState("");
  const [tags, setTags] = useState("");
  const [courses, setCourses] = useState([]);
  const [editorContent, setEditorContent] = useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setMetaDescription(event.target.value);
  };

  const handleLogoImageChange = (event) => {
    setImage(event.target.value);
  };

  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };

  const handleTagsChange = (event) => {
    setTags(event.target.value);
  };

  const handleAddCourseInput = () => {
    setCourses([...courses, ""]);
  };

  const handleRemoveCourseInput = (index) => {
    const updatedInputs = [...courses];
    updatedInputs.splice(index, 1);
    setCourses(updatedInputs);
  };

  const handleCourseInputChange = (index, value) => {
    const updatedInputs = [...courses];
    updatedInputs[index] = value;
    setCourses(updatedInputs);
  };

  const handleEditorChange = (content, editor) => {
    setEditorContent(content); // Capture the updated content
  };

  const context = useOutletContext()
  context(["Blog", "Create Blogs"]);

  // Snackbar code
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const openSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };


  const handleSave = async () => {
    const postData = {
      title: title,
      metaDescription: metaDescription,
      author: author,
      image: image,
      tags: tags.split(","), // Convert tags string to an array
      content: editorContent, // Use the captured content
      courses: courses
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blogs`,
        postData
      );
      console.log("Blog post created:", response.data);
      openSnackbar("success", "Blog post created successfully");
    } catch (error) {
      console.error("Error creating blog post:", error);
      openSnackbar("error", "Error creating blog post (Title, Author and Content is Required)");
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageUpload = async () => {

    if (!selectedImage) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", selectedImage);

      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/cloudinary/uploadimage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadedImageUrl(response.data.secure_url)
      // Handle successful upload, e.g., display the uploaded image
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle upload error
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <input
        type="text"
        className="border-solid border-2 border-gray p-1"
        placeholder="Title (Required)"
        value={title}
        onChange={handleTitleChange}
      />
      <input
        type="text"
        className="border-solid border-2 border-gray p-1"
        placeholder="Meta Description (Required)"
        value={metaDescription}
        onChange={handleDescriptionChange}
      />
      <input
        type="text"
        className="border-solid border-2 border-gray p-1"
        placeholder="Blog Outer Image (Please upload the image below and the paste the link here)"
        value={image}
        onChange={handleLogoImageChange}
      />
      <input
        type="text"
        className="border-solid border-2 border-gray p-1"
        placeholder="Tags (comma-separated)"
        value={tags}
        onChange={handleTagsChange}
      />
      <div className="flex gap-x-2 items-center overflow-x-scroll">
        {courses?.map((courseInput, index) => (
          <div key={index} className="flex gap-x-2 items-center">
            <input
              type="text"
              className="border-solid border-2 border-gray p-1"
              placeholder="Course Slug"
              value={courseInput}
              onChange={(e) => handleCourseInputChange(index, e.target.value)}
            />
            {index > 0 && (
              <button
                className="bg-red-500 h-8 w-8 text-white p-1"
                onClick={() => handleRemoveCourseInput(index)}
              >
                -
              </button>
            )}
          </div>
        ))}
        <button
          className="bg-green-500 h-8 w-8 text-white p-1"
          onClick={handleAddCourseInput}
        >
          +
        </button>
      </div>
      <input
        type="text"
        className="border-solid border-2 border-gray p-1"
        placeholder="Author (Required)"
        value={author}
        onChange={handleAuthorChange}
      />
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
      <button className="w-[150px] h-[40px] bg-blue-400 text-white" onClick={handleImageUpload}>
        Upload Image
      </button>
      {uploadedImageUrl}
      {uploadedImageUrl && (
        <img className="h-32 w-32" src={uploadedImageUrl} alt="Uploaded" />
      )}
      <Editor
        apiKey="qw3c3bk7e87wky4em7jtqpijqm6nx8gt4mmb8ew5krn5pvvb"
        initialValue="<p>Please delete this content and add new content otherwise the blog will not be created.</p>"
        init={
          {
            plugins: ["advlist autolink lists link"],
          }
        }
        onEditorChange={handleEditorChange}
      />
      <button className="w-[150px] h-[40px] bg-blue-400 text-white" onClick={handleSave}>Create Blog Post</button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={closeSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default TinyMCEEditor;
