import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const Navbar = (props) => {
  return (
    <div className='h-14 mx-3 border-t-gray-300 border-b-gray-300 border flex items-center'>
      <div className='grid grid-flow-col py-4 px-2'>
        {
          props.path.map((path, index) => {
            return (
              <div className='col-span-1' style={{ maxWidth: 'max-content' }}>
                {index != props.path.length - 1 ?
                  <h4 className='text-sm font-medium uppercase' style={{ color: '#4B4B4B', display: 'inline', maxWidth: 'max-content' }}>{path} &nbsp;&nbsp; <ArrowForwardIosIcon fontSize='inherit' /> &nbsp;&nbsp;</h4>
                  :
                  <h4 className='text-sm font-medium uppercase' style={{ color: '#644fdc', display: 'inline' }}>{path}</h4>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Navbar